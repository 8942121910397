<template>
    <div class="d-flex justify-content-center">

        <div class="card text-center" v-if="!starting">
            <div class="card-header">
                <img src="/images/logo-abk.png" alt="">
                <hr>
                <h1>Anestezi Soru Bankası</h1>
            </div>
            <div class="card-body">
                <button class="btn btn-success btn-block" @click="Start()">Çözmeye Başla</button>
            </div>
            <div class="card-footer text-muted">

            </div>
        </div>

        <div class="card text-center" v-if="sonlandir==0 && starting" @click.right.prevent @keydown="keydown"
             @copy.prevent @paste.prevent>
            <div class="card-header">
                <img src="/images/logo-abk.png" alt="">
                <hr>
                <h1>Anestezi Soru Bankası</h1><br>
                <h2>Kalan Süre {{ time }} Saniye</h2>
                <progress class="progress is-info" style="width: 100%" :value="(time/60)*100" max="100">
                    {{ (time / 60) * 100 }}%
                </progress>
            </div>
            <div class="card-body">

                <h2 class="text-red">Konu: {{ sorulars[currentQuestion].issue }}</h2>
                <h5 class="card-title">
                    {{ this.currentQuestion + 1 }}. Soru) {{ sorulars[currentQuestion].question }}
                </h5>
                <ul class="list-group" v-for="(answer, key, index) in sorulars[currentQuestion].cevap">
                    <li class="list-group-item"
                        :class="{'active':cevaplar[sorulars[currentQuestion].id]===answer}"
                        @click="cevap(currentQuestion,answer,sorulars[currentQuestion].id)"
                    >{{ createAnswer(key,answer) }}
                    </li>
                </ul>

            </div>

        </div>


        <div class="card text-center" v-if="sonlandir>0 && starting">
            <div class="card-header">
                <img src="/images/logo-abk.png" alt="">
                <hr>
                <h1>Anestezi Soru Bankası</h1><br>
            </div>
            <div class="card-body">
                <div class="p-3 mb-2 bg-success text-white">Doğru Sayısı: {{ score }}</div>
                <div class="p-3 mb-2 bg-danger text-white">Yanlış Sayısı: {{ yanlisCevap }}</div>
                <button class="btn btn-primary btn-block" @click="restart()">Yenile <i class="fas fa-sync"></i></button>
                <span></span> <br>
                <span></span> <br>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sonlandir: 0,
            starting: false,
            time: 60,
            bosCevap: 0,
            yanlisCevap: 0,
            currentQuestion: 0,
            sorulars: {},
            cevaplar: [],
            score: 0,
        }
    },

    methods: {
        loadSorulars() {

            // if(this.$gate.isAdmin()){
            axios.get("api/sorucek").then(({data}) => (this.sorulars = data.data));
            // }
        },
        createAnswer(id,cevap){
            switch (id) {
                case 0:
                    return 'A) '+cevap;
                    break;
                case 1:
                    return 'B) '+cevap;
                    break;
                case 2:
                    return 'C) '+cevap;
                    break;
                case 3:
                    return 'D) '+cevap;
                    break;
                default:
                    return 'E) '+cevap;
            }
        },
        cevap(id, cevap, soruNo) {

            this.cevaplar.push({ cevap: cevap, soruNo: soruNo });
            this.Next();
        },
        Next() {
            if (this.currentQuestion < this.sorulars.length - 1) {
                this.currentQuestion++;
                this.time = 60;
            } else {
                this.Finish();
            }
        },
        Prev() {
            if (this.currentQuestion > 0) {
                this.currentQuestion--;
                this.time = 60;
            }
        },
        Finish() {
            this.time = 0;
            this.sonlandir = 1;

            axios.post("api/checkSoru",{cevaplar:this.cevaplar}).then(({data}) => {
                this.score = data;
                this.yanlisCevap = 10-data;
            });

        },
        Start() {
            this.starting = true;
            this.countDownTimer();
        },
        restart() {
            window.location.reload();
        },
        keydown: function (e) {
            console.log(e)
        },
        countDownTimer() {
            if (this.time > 0 && this.currentQuestion < this.sorulars.length && this.sonlandir === 0) {
                setTimeout(() => {
                    this.time -= 1;
                    this.countDownTimer();
                }, 1000);
            } else if (this.time === 0 && this.currentQuestion < this.sorulars.length && this.sonlandir === 0) {
                this.Next();
                this.countDownTimer();
            } else if (this.currentQuestion === this.sorulars.length || this.sonlandir === 1) {
                this.Finish();
            }
        },

    },
    created() {
        this.loadSorulars();
        //  this.countDownTimer();
    }
}


</script>

<style>
body {
    font-family: "Nunito", sans-serif;
    font-weight: normal;
}

card {
    min-width: 100%;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
}

card-q {
    min-width: 60%;
}

.ans-option-btn {
    min-width: 50%;
    font-size: 16px;
    color: #ffffff;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;


}

.answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
}

.card-img, .card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    height: 350px;
}

.ans-option-btn {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
}

.list-group-item {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.list-group-item:hover {
    color: #fff;
    background-color: #3490dc;
}

.text-red {
    color: red;
}
</style>
